.profilePage {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profileInfo {
  display: flex;
  justify-content: left;
  width: 100%;
}
