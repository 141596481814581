body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  margin: 0 5px;
  padding: 5px 10px;
}
body {
  font-family: "Libre Baskerville", serif;
}

h3 {
  font-family: "Montserrat", serif;
  font-size: 24px;
  font-weight: 1000;
}

.card {
  background: #e4f2f0;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 10px 0;
}

.button {
  background-color: #2eb9ac;
  border: 1px solid #2eb9ac;
  border-radius: 3px;
  color: #262626;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0 5px;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  color: white;
}

.button:hover {
  background-color: #fafafa;
  color: black;
}
