.title-holder {
  margin-top: 0;
  padding-top: 0;
  display: flex;
  justify-content: center;
}
.post-card-activity {
  display: flex;
  justify-content: space-between;
}

.post-card-activity-section {
  display: flex;
  align-items: center;
}
