.comment {
  background: #cef0eb;
  border-bottom: 1px solid black;
}
.body {
  margin-left: 15px;
  padding-top: 1px;
}
.username-holder {
  display: flex;
  justify-content: right;
  padding-bottom: 0;
}
