.comment-box {
  background: white;
  border: none;
  border-bottom: 1px solid black;
  width: 100%;
  height: 30px;
  display: flex;
}
.text-box {
  width: 97%;
  height: 94%;
  border: none;
  border-bottom: 1px solid black;
}
/* .body{
    margin-left: 15px;
    padding-top: 1px;
}
.username-holder{
    display:flex;
    justify-content: right;
    padding-bottom: 0;
} */
